import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import * as XLSX from 'xlsx';

const MusteriListesi = () => {
  const [musteriDurum, setMusteriDurum] = useState({});
  const [musteriOdemeDurum, setMusteriOdemeDurum] = useState({});
  const [apiMusteriler, setApiMusteriler] = useState([]);

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = apiMusteriler.map((musteri) => ({
      'Üye Numarası': musteri.Musteri_Uye_NO,
      'Adı': musteri.Musteri_Ad,
      'Soyadı': musteri.Musteri_Soyad,
      'Telefon No': musteri.Musteri_Telefon1,
      'Aktivasyon Tarihi': musteri.Paket_Baslangic_Tarihi,
      'Bitiş Tarihi': musteri.Paket_Bitis_Tarihi,
      'Paket Türü': musteri.Paket_Turu === 0 ? 'Bireysel' : 'Ticari',
      'Ödeme Şekli': musteri.Paket_Odeme_Turu,
      'Ödeme Durumu': musteri.Paket_Odeme_Durumu,
      'Durumu': musteri.Paket_Durum,
      'Toplam Tutar':musteri.Paket_Hesaplanan_Fiyat  
    }));

    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'MusteriListesi');

    XLSX.writeFile(workbook, 'MusteriListesi.xlsx');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.digiturk.az/api/Odenis/MusterileriListele');
        setApiMusteriler(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (musteriId, selectedDurum) => {
    setMusteriDurum((prevDurum) => ({
      ...prevDurum,
      [musteriId]: selectedDurum,
    }));
  };

  const handleChangeOdemeDurumu = (musteriId, selectedDurum) => {
    setMusteriOdemeDurum((prevDurum) => ({
      ...prevDurum,
      [musteriId]: selectedDurum,
    }));
  };

  const handleUpdateStatus = async (musteriId, paketID) => {

    const newPaketDurum = musteriDurum[musteriId] || apiMusteriler.find((m) => m.Musteri_Paket_ID === musteriId).Paket_Durum;
    const newOdemeDurum = musteriOdemeDurum[musteriId] || apiMusteriler.find((m) => m.Musteri_Paket_ID === musteriId).Paket_Odeme_Durumu;
    console.log(newOdemeDurum, 'newOdemeDurum');
    const postData = {
      Musteri_Paket_ID: musteriId,
      paket_ID: paketID,
      paket_Durum: newPaketDurum,
      Paket_Odeme_Durumu: newOdemeDurum,
    };
    try {
      const response = await axios.post('https://api.digiturk.az/api/Odenis/MusteriPaketiDurumuGuncelle', postData, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
        },
      });

      // Update local state after a successful response
      setApiMusteriler((prevMusteriler) =>
        prevMusteriler.map((musteri) =>
          musteri.Musteri_Paket_ID === musteriId
            ? { ...musteri, Paket_Durum: newPaketDurum, Paket_Odeme_Durumu: newOdemeDurum }
            : musteri
        )
      );

      alert('İşleminiz Başarıyla Gerçekleşti');
    } catch (error) {
      console.error('API Error:', error);
      alert('İşleminiz Gerçekleşemedi', error);
    }
  };

  // Convert data to a format compatible with MDBDataTable
  const data = {
    columns: [
      {
        label: 'Üye Numarası',
        field: 'Musteri_Uye_NO',
        sort: 'asc',
      },
      {
        label: 'Adı',
        field: 'Musteri_Ad',
      },
      {
        label: 'Soyadı',
        field: 'Musteri_Soyad',
      },
      {
        label: 'Telefon No',
        field: 'Musteri_Telefon1',
      },
      {
        label: 'Aktivasyon Tarihi',
        field: 'Paket_Baslangic_Tarihi',
      },
      {
        label: 'Bitiş Tarihi',
        field: 'Paket_Bitis_Tarihi',
      },
      {
        label: 'Paket Türü',
        field: 'Paket_Turu',
        
      },
      {
        label: 'Ödeme Şekli',
        field: 'Paket_Odeme_Turu',
      },
      {
        label: 'Toplam Tutar',
        field: 'Paket_Hesaplanan_Fiyat',
        
      },
      {
        label: 'Ödeme Durumu',
        field: 'Paket_Odeme_Durumu',
      },
      {
        label: 'Durumu',
        field: 'Paket_Durum',
      },
      {
        label: 'İşlemler',
        field: 'actions',
      },
    ],

    rows: apiMusteriler.map((musteri) => {
      const paketDurum = musteriDurum[musteri.Musteri_Paket_ID] || musteri.Paket_Durum;
      const isPasif = paketDurum === 'Pasif';
      const bitisTarihi = new Date(musteri.Paket_Bitis_Tarihi);
      const today = new Date();

      const isBitisTarihiYaklasti = bitisTarihi - today < 7 * 24 * 60 * 60 * 1000; // 7 gün kala mavi renk

      return {
        Musteri_Uye_NO: musteri.Musteri_Uye_NO,
       
      Musteri_Ad: musteri.Musteri_Ad,
      Musteri_Soyad: musteri.Musteri_Soyad,
      Musteri_Telefon1: musteri.Musteri_Telefon1,
      Paket_Baslangic_Tarihi: musteri.Paket_Baslangic_Tarihi,
      Paket_Bitis_Tarihi: (
        <span style={{ color: isBitisTarihiYaklasti ? 'blue' : '' }}>
          {musteri.Paket_Bitis_Tarihi}
        </span>
      ),
      Paket_Turu: musteri.Paket_Turu === 0 ? 'Bireysel' : 'Ticari',
      Paket_Odeme_Turu: musteri.Paket_Odeme_Turu,
      Paket_Hesaplanan_Fiyat:musteri.Paket_Hesaplanan_Fiyat+" ₼",
      Paket_Odeme_Durumu: (
        <select
          value={musteriOdemeDurum[musteri.Musteri_Paket_ID] || musteri.Paket_Odeme_Durumu}
          onChange={(e) => handleChangeOdemeDurumu(musteri.Musteri_Paket_ID, e.target.value)}
        >
          <option value="Ödeme Alındı">Ödeme Alındı</option>
          <option value="Ödeme Alınmadı">Ödeme Alınmadı</option>
        </select>
      ),
        Paket_Durum: (
          <select
            value={musteriDurum[musteri.Musteri_Paket_ID] || musteri.Paket_Durum}
            onChange={(e) => handleChange(musteri.Musteri_Paket_ID, e.target.value)}
          >
            <option value="Aktif">Aktif</option>
            <option value="Pasif">Pasif</option>
            <option value="Beklemede">Beklemede</option>
          </select>
        ),
        actions: (
          <button
            onClick={() => handleUpdateStatus(musteri.Musteri_Paket_ID, musteri.Paket_ID)}
            style={{
              backgroundColor: isPasif ? '#d9534f' : '#d1a216',
              color: 'white',
              cursor: 'pointer',
              marginLeft: '5px',
            }}
          >
            Güncelle
          </button>
        ),
        className: isPasif ? 'liste' : '',      };
    }),
  
  };

  return (
    <>
 <section className="ftco-section" style={{ marginTop: '-155px', width: '1500px', marginLeft: '-330px',height:"100%" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row no-gutters">
                  <div className="col-md-12">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">MÜŞTERİ LİSTESİ</h3>
                      <button onClick={handleExportToExcel} style={{ backgroundColor: '#28a745', color: 'white', cursor: 'pointer', marginTop: '10px' }}>
                        Excel'e Aktar
                      </button>
                      <MDBDataTable
                        striped
                        bordered
                        small
                        data={data}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MusteriListesi;
